import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { revive_store } from '~/pages/daddy960_revive/revive_store';
/** MACD */
export const daddy960_revive_macd = createIndicator({
    id: 'daddy960-revive-macd',
    displayName: 'MACD',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const fastLength = 12; //this._input(0)
            const slowLength = 26; // this._input(1)
            const macdLength = 9; // this._input(2)
            const close = this.ohlc.closeArray;
            close.get(100);
            const macd = this.macd(fastLength, slowLength, macdLength);
            const osc = context.new_var(macd.osc);
            const ema = context.new_var(this.exponentialAverage(close, 17));
            osc.get(100);
            ema.get(100);
            const isRed = ema.get(0) > ema.get(1) && osc.get(0) > osc.get(1);
            const isGreen = ema.get(0) < ema.get(1) && osc.get(0) < osc.get(1);
            const isBlue = !isRed && !isGreen;
            if (osc.get(0) >= 0) {
                revive_store.macdState = 'golden';
            }
            else {
                revive_store.macdState = 'death';
            }
            return [
                isRed ? macd.osc : NaN,
                isGreen ? macd.osc : NaN,
                isBlue ? macd.osc : NaN,
                macd.dif,
                macd.macd,
                0,
            ];
        },
    },
    metainfo: {
        is_price_study: false,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        plots: [
            { id: 'oscRed', type: 'line' },
            { id: 'oscGreen', type: 'line' },
            { id: 'oscBlue', type: 'line' },
            { id: 'diff', type: 'line' },
            { id: 'dea', type: 'line' },
            { id: 'zero', type: 'line' },
        ],
        defaults: {
            styles: {
                oscRed: {
                    linestyle: 0,
                    linewidth: 3,
                    plottype: 1,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#e15241',
                },
                oscGreen: {
                    linestyle: 0,
                    linewidth: 3,
                    plottype: 1,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#67ad5b',
                },
                oscBlue: {
                    linestyle: 0,
                    linewidth: 3,
                    plottype: 1,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#4994ec',
                },
                diff: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#f8f8f9',
                },
                dea: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ffff00',
                },
                zero: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#8a8d96',
                },
            },
            precision: 2,
        },
        styles: {
            oscRed: {
                title: 'OSC',
                histogramBase: 0,
                joinPoints: false,
            },
            oscGreen: {
                title: 'OSC',
                histogramBase: 0,
                joinPoints: false,
            },
            oscBlue: {
                title: 'OSC',
                histogramBase: 0,
                joinPoints: false,
            },
            diff: {
                title: 'DIFF',
                histogramBase: 0,
                joinPoints: false,
            },
            dea: {
                title: 'DEA',
                histogramBase: 0,
                joinPoints: false,
            },
            zero: {
                title: '',
                histogramBase: 0,
                joinPoints: false,
            },
        },
        inputs: [],
    },
});
