import { proxy } from 'valtio';
export const revive_store = proxy({
    stockSelectionType: 'long',
    stockSelectionCondition: [],
    stockSymbol: ['2330'],
    filterStockFutures: false,
    sidePane2State: 'stock',
    macdState: 'golden',
    lifeLineState: 'golden',
});
